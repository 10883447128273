import { 
  DashboardOutlined, 
  AppstoreOutlined,
  FileTextOutlined,
  PieChartOutlined,
  EnvironmentOutlined,
  AntDesignOutlined,
  SafetyOutlined,
  StopOutlined,
  DotChartOutlined,
  MailOutlined,
  MessageOutlined,
  CalendarOutlined,
  BulbOutlined,
  InfoCircleOutlined,
  CompassOutlined,
  LayoutOutlined,
  DesktopOutlined,
  FileDoneOutlined,
  CommentOutlined,
  RobotOutlined,
  PlusCircleOutlined,
  FundOutlined,
  ShoppingCartOutlined,
  BookOutlined,
  FileUnknownOutlined,
  ProfileOutlined
} from '@ant-design/icons';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH } from 'configs/AppConfig'



const dashBoardNavTree = [{
  key: 'dashboards',
  path: `${APP_PREFIX_PATH}/dashboards/`,
  title: 'sidenav.dashboard',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    // {
    //   key: 'dashboards-default',
    //   path: `${APP_PREFIX_PATH}/dashboards/default`,
    //   title: 'sidenav.dashboard.default',
    //   icon: DashboardOutlined,
    //   breadcrumb: false,
    //   submenu: []
    // },


  
   
  ]
}]



const quotesNavTree = [{
  key: 'quotes-request',
  path: `${APP_PREFIX_PATH}/quotes-request`,
  title: 'sidenav.quotes_request',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
      
  ]
}] 

const catererNavTree = [{
  key: 'caterers',
  path: `${APP_PREFIX_PATH}/caterers`,
  title: 'sidenav.caterers',
  icon: DashboardOutlined,
  breadcrumb: true,
  submenu: [
      
  ]
}] 






const mastersNavTree = [{
  key: 'masters',
  path: `${APP_PREFIX_PATH}/masters`,
  title: 'sidenav.masters',
  icon: DashboardOutlined,
  breadcrumb: false,
  submenu: [
    {
      key: 'masters-recepimenus',
      path: `${APP_PREFIX_PATH}/recipemenus`,
      title: 'sidenav.masters.recepimenus',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'masters-foodtypes',
      path: `${APP_PREFIX_PATH}/masters/foodtypes`,
      title: 'sidenav.masters.foodtypes',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'masters-occations',
      path: `${APP_PREFIX_PATH}/masters/occasions`,
      title: 'sidenav.masters.occasions',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    },
    {
      key: 'masters-foods',
      path: `${APP_PREFIX_PATH}/masters/foods`,
      title: 'sidenav.masters.foods',
      icon: DashboardOutlined,
      breadcrumb: false,
      submenu: []
    }



    
  ]
}]






const navigationConfig = [
  ...dashBoardNavTree,
  // ...appsNavTree,
  ...quotesNavTree,
  ...mastersNavTree,
  ...catererNavTree

]

export default navigationConfig;
